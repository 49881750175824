.new-app > .aumee--container {
    width: calc(100% - var(--padding-epic) * 2);
    max-width: 800px;
    height: 60%;
}

.new-app > .aumee--container > .app-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.app-info > .colors {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    background-color: var(--selected-theme-secondary-light);
}

.app-info > .colors > .color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
}

.app-info > .colors > .color > span {
    font-size: var(--font-size-heading);
    font-weight: bold;
}

@media screen and (max-width: 700px) {
    .new-app > .aumee--container {
        height: 75%;
    }

    .app-info > .colors {
        flex-direction: column;
    }
}
