.app-info {
    overflow-y: scroll;
    overflow-x: hidden;

    justify-content: flex-start;
}

.app-info .absolute-button {
    position: absolute;
}

.app-info > .aumee--container[id*='container_info'] {
    min-width: 350px;
}

.app-info > .accesses {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-info > .accesses > .aumee--container {
    width: 250px;
    height: 400px;
}

.app-info
    > .accesses
    > .aumee--container[id*='container_roles']:has(.aumee--editable-object) {
    width: 340px !important;
}

.app-info > .accesses > .aumee--container:first-child {
    margin-right: var(--margin-medium);
}

.app-info > .aumee--container[id*='container_colors'] {
    width: calc(100% - var(--padding-medium) * 2);
}

.app-info .data-display {
    padding: var(--padding-medium);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.app-info .data-display > .spans {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    text-align: center;
}

.app-info .data-display > .spans > .attribute {
    font-size: var(--font-size-paragraph);
    text-align: center;
    width: 100%;
}

.app-info .data-display > .spans > .aumee--container[id*='container_basic'] {
    width: calc(100% - var(--padding-medium) * 2);
}

.app-info .data-display > .spans .ellipsis {
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden; /* keeps the element from overflowing its parent */
}

.app-info .data-display > .spans .basic {
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-info .data-display > .spans .basic > span {
    font-size: var(--font-size-paragraph);
}

#app-info_tooltip_reset-basic,
#app-info_tooltip_authorize-add-access {
    width: 100%;
}

.app-info .data-display .icon {
    width: 100px;
    height: 100px;

    border: 2px solid var(--selected-theme-primary-light);
    border-radius: 10px;
}

.app-info .app-colors {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-grow: 1;
}

.app-info .app-colors > .app-color-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.app-info .app-colors > .app-color-section > .color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: var(--margin-small);
    flex-grow: 1;
}

.app-info .app-colors > .app-color-section > .color > span {
    font-size: var(--font-size-heading);
    font-weight: bold;
}

@media screen and (max-width: 700px) {
    .app-info > .aumee--container[id*='container_info'] {
        min-width: 225px;
    }

    .app-info .data-display {
        padding: 0px;
        flex-direction: column;
    }

    .app-info .data-display .icon {
        width: 40px;
        height: 40px;
    }

    .app-info > .accesses {
        flex-direction: column;
    }

    .app-info > .accesses > .aumee--container:first-child {
        margin-right: var(--margin-none);
        margin-bottom: var(--margin-medium);
    }

    .app-info .app-colors {
        flex-direction: column;
    }

    .app-info .app-colors > .app-color-section {
        flex-direction: column;
    }
}
