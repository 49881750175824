.app {
    height: calc(100vh);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manager {
    display: flex;
    width: 100%;
    height: 100%;
}

.manager.top {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.manager.left {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.manager > .content {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manager.top > .content {
    height: calc(100% - 70px);
    width: 100%;
}

.manager.left > .content {
    width: calc(100% - 170px);
}

.manager > .content > .app-loading {
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--selected-theme-secondary);
}

.app-loading.invisible {
    animation: aumee--fadeIn 0.4s reverse ease;
    opacity: 0;
}

.manager > .content > .app-loading > .aumee-loader {
    transform: translate(-50%, -50%);
}

.content > .empty-app-id {
    font-size: var(--font-size-subtitle);
}
