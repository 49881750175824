:root {
    --logs-error-color: #e67474;
    --logs-status-color: #74cde6;
    --logs-analytics-color: #7489e6;
    --logs-info-color: #74e67d;
    --logs-debug-color: #e0e674;

    --status-2xx-color: #3ee24c;
    --status-3xx-color: #3e54e2;
    --status-4xx-color: #e2a33e;
    --status-5xx-color: #e23e3e;
}

body {
    margin: 0;
    font-family: 'Montserrat';
    overflow: hidden;
}

#root {
    background-color: var(--select-theme-secondary);
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--padding-epic);
    width: calc(100% - var(--padding-epic) * 2);
    height: calc(100% - var(--padding-epic) * 2);
}

/* DEFINE MEDIA QUERY IN SYSTEM DESIGN */
@media screen and (max-width: 825px) {
    .page {
        padding: var(--padding-medium);
        width: calc(100% - var(--padding-medium) * 2);
        height: calc(100% - var(--padding-medium) * 2);
    }
}
