.mermaid {
    height: 100%;
    font-size: 1px;
}

.mermaid > svg {
    height: 100%;
}

.app-2xx > circle,
.app-2xx > polygon,
.app-2xx > rect {
    stroke: var(--status-2xx-color) !important;
    fill: #aef3b3 !important;
}

.app-5xx > circle,
.app-5xx > polygon,
.app-5xx > rect {
    stroke: var(--status-5xx-color) !important;
    fill: #f9bebe !important;
}

.app-4xx > circle,
.app-4xx > polygon,
.app-4xx > rect {
    stroke: var(--status-4xx-color) !important;
    fill: #f2d6aa !important;
}

.app-3xx > circle,
.app-3xx > polygon,
.app-3xx > rect {
    stroke: var(--status-3xx-color) !important;
    fill: #aebcfe !important;
}
