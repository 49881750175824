.filter {
    border-radius: 10px;
    border: 2px solid var(--selected-theme-primary);
    padding: var(--padding-small);
    margin: var(--margin-small);
}

.filter > .text {
    flex-wrap: wrap;
}

.filter > .text > span {
    font-size: var(--font-size-paragraph);
    color: var(--selected-theme-primary);
    text-align: center;
}

.filter > .text > .label {
    white-space: nowrap;
    font-weight: bold;
}
