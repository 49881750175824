.translator > .aumee--container {
    width: calc(100% - var(--padding-medium) * 2);
    height: calc(90% - var(--padding-medium) * 2);
}

.translator > .aumee--container > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.translator .value-side .centered > .not-created {
    font-size: var(--font-size-subtitle);
}

.translator .value-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
}

.value-side > .head {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    border-bottom: 3px solid var(--selected-theme-tertiary);
}

.value-side .cell {
    display: flex;
    justify-content: center;
    align-items: center;

    height: calc(100% - var(--padding-large) * 2);
}

.value-side > .centered {
    flex-grow: 1;
}

.value-side .row > .aumee--tooltip {
    height: 100%;
}

.value-side > .head .cell {
    position: relative;
}

.value-side > .head .cell,
.value-side > .bottom .cell {
    padding: var(--padding-small) var(--padding-medium);
}

.value-side > .head .cell.locale,
.value-side > .bottom .cell.locale {
    height: calc(100% - (var(--padding-small) * 2));
}

.value-side .cell.default {
    min-width: 150px;
    max-width: 150px;
    text-align: center;
}

.value-side .cell.locale {
    flex-grow: 1;

    border-left: 2px solid var(--selected-theme-tertiary);

    display: flex;
    justify-content: center;
    align-items: center;
}

.value-side .cell.locale.compare {
    flex-grow: 0;
    transition: width 0.3s ease, min-width 0.3s ease, opacity 0.3s ease;
}

.value-side .cell.locale.compare.close {
    width: 20px;
    min-width: 20px;
}

.value-side .cell.locale.compare.open {
    width: 330px;
    min-width: 330px;
    opacity: 1;
}

.value-side .cell.translation.locale.compare.close {
    width: 0px !important;
    height: 0px;
    min-width: 0px !important;
    padding: 0px !important;
    border: none;
    overflow: hidden;
    visibility: collapse;
    opacity: 0;
}

.value-side .cell.locale.compare > .aumee--select {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.value-side .cell.locale.compare.close > .aumee--select {
    opacity: 0;
    user-select: none;
    pointer-events: none;
}

.value-side > .content {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    overflow-x: clip;
    overflow-y: scroll;
}

.value-side > .content .cell {
    padding: var(--padding-large) var(--padding-medium);
}

.value-side > .content .cell.locale {
    height: calc(100% - (var(--padding-large) * 2));
}

.value-side > .content > .row {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    border-bottom: 2px solid var(--selected-theme-tertiary-light);
    background-color: var(--selected-theme-secondary-light);
    flex-grow: 1;
}

.value-side > .content > .row:last-child {
    border-bottom: none;
}

.value-side > .content > .row.add .cell.locale {
    padding: 0px;
    height: 100%;
    font-size: var(--font-size-subtitle);
}

.value-side > .content > .row.add > .cell.no-button {
    border-left: none !important;
}

.value-side > .bottom {
    display: flex;
    align-items: center;

    width: 100%;

    border-top: 3px solid var(--selected-theme-tertiary);
    background-color: var(--selected-theme-secondary-light);
}

/* DEFINE MEDIA QUERY IN SYSTEM DESIGN */
@media screen and (max-width: 999px) {
    .value-side .cell.locale.compare,
    .value-side .cell.locale.compare,
    .value-side .cell.locale.compare {
        min-width: 0px !important;
        width: 0px !important;
        padding: 0px !important;
        visibility: collapse;
        border: none;
        overflow: hidden;
    }
}

/* DEFINE MEDIA QUERY IN SYSTEM DESIGN */
@media screen and (max-width: 825px) {
    .value-side .cell.default {
        min-width: 100px;
        max-width: 100px;
    }
}
