@property --total-value {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
}

.total-data > .value {
    transition: --total-value 2.5s;
    counter-set: total-value var(--total-value);
    font-size: var(--font-size-title);
    font-weight: bold;
    padding: var(--padding-epic);
}
.total-data > .value::after {
    content: counter(total-value);
}

.total-data > .label {
    font-size: var(--font-size-heading);
    text-align: center;
}

@media screen and (max-width: 1450px) {
    .total-data > .value {
        font-size: var(--font-size-subtitle);
        padding: var(--padding-large);
    }

    .total-data > .label {
        font-size: var(--font-size-button);
    }
}

@media screen and (max-width: 700px) {
    .total-data > .value {
        font-size: var(--font-size-heading);
        padding: var(--padding-medium);
    }

    .total-data > .label {
        font-size: var(--font-size-paragraph);
    }
}
