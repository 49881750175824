.analyst > .aumee--container[id*='container_data'] {
    flex-grow: 1;
    width: calc(100% - var(--padding-epic) * 2);
    height: 25%;
}

.analyst .inputs {
    flex-wrap: wrap;
    height: 100%;
}

.analyst .inputs > div {
    margin: var(--margin-small);
}

.analyst .filters {
    flex-wrap: wrap;
    position: relative;
}

.analyst .filters > .no-filter {
    font-size: var(--font-size-button);
    color: var(--selected-theme-primary);
    padding: var(--padding-large);
    margin-top: calc(var(--padding-tiny) - 0.4px);
}

.analyst .filters > .add-data {
    width: 22px;
    position: absolute;
    background-color: var(--selected-theme-secondary-light);
    top: 100%;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    border-radius: 10px;

    transition: width 0.2s ease;
}

.analyst .filters > .add-data.background {
    width: 220px;
}

.analyst .filters > .inputs-data {
    width: 200px;

    z-index: 1;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--selected-theme-secondary-light);
    height: 130px;
    padding: var(--padding-epic) var(--padding-medium) var(--padding-medium)
        var(--padding-medium);
    border-radius: 10px;
    box-shadow: var(--selected-theme-box-shadow);
    clip-path: inset(10px -10px -10px -10px);
    overflow: hidden;

    transition: padding 0.4s ease, height 0.4s ease;
}

.analyst .filters > .inputs-data.invisible {
    height: 0px;
    padding: var(--padding-none) var(--padding-medium);
    user-select: none;
    pointer-events: none;
}

#analyst_container_data > .row {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

#analyst_container_data > .row > .column > .graphs,
#analyst_container_data > .row > .column > .totals {
    width: 100%;
}

#analyst_container_data > .row > .column > .totals,
#analyst_container_data > .row > .column > .graphs,
#analyst_container_data > .row > .column > .lists,
#analyst_container_data > .row > .column,
#analyst_container_data > .row {
    flex-wrap: wrap;
}

#analyst_container_data > .row > .column:first-child,
#analyst_container_data > .row > .column > .graphs {
    flex-grow: 2;
}

#analyst_container_data > .row > .column {
    height: 100%;
    flex-grow: 1;
}

#analyst_container_data > .row > .column.lists {
    flex-direction: column;
    flex-wrap: nowrap !important;
    flex-grow: 1;
    align-items: stretch;
}

#analyst_container_data > .row > .column > .totals,
#analyst_container_data > .row > .column > .totals > div,
#analyst_container_data > .row > .column > .graphs > .aumee--pie-chart,
#analyst_container_data > .row > .column.lists > .aumee--data-list {
    flex-grow: 1;
}

#analyst_data-list_session-id {
    max-height: 30%;
    min-height: 200px;
}

@media screen and (max-width: 1450px) {
    #analyst_container_data > .row > .column.lists {
        flex-direction: row;
    }

    #analyst_container_data > .row > .column,
    #analyst_container_data > .row > .column.lists {
        height: fit-content;
    }

    #analyst_data-list_session-id {
        max-height: none;
    }

    #analyst_data-list_session-id,
    #analyst_data-list_events {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    #analyst_container_data > .row > .column.lists {
        flex-direction: column;
    }
}
