.notificator > .aumee--container[id*='container_inputs'] {
    width: calc(100% - var(--padding-large) * 2);
}

.notificator > .aumee--container[id*='container_locale-mail'] {
    flex-grow: 1;
    width: calc(100% - var(--padding-epic) * 2);
    height: 25%;
}

#notificator_container_inputs > .inputs {
    flex-direction: row;
    flex-wrap: wrap;
}

#notificator_span_template-name {
    font-size: var(--font-size-heading);
}

#notificator_container_locale-mail > .locale-mail {
    height: 100%;
}

#notificator_container_locale-mail > .locale-mail > .mail {
    flex-grow: 1;
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#notificator_container_locale-mail > .locale-mail > .mail > .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

#notificator_container_locale-mail
    > .locale-mail
    > .mail
    > .actions
    > .moving-icon {
    transition: transform 0.3s ease, opacity 0.3s ease;
}

#notificator_container_locale-mail
    > .locale-mail
    > .mail
    > .actions
    > .moving-icon.origin {
    transform: translateY(0px) scale(1);
    opacity: 1;
}

#notificator_container_locale-mail
    > .locale-mail
    > .mail
    > .actions
    > .moving-icon.fly {
    transform: translateY(-50px) scale(0);
    opacity: 0;
}

#notificator_container_locale-mail > .locale-mail > .mail > .render,
#notificator_container_locale-mail > .locale-mail > .mail > .modify {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#notificator_container_locale-mail > .locale-mail > .mail > .render > .title {
    text-align: center;
    font-size: var(--font-size-heading);
    width: 100%;
}

#notificator_container_locale-mail > .locale-mail > .mail > .render > .content {
    border: 1px solid var(--selected-theme-tertiary-light);

    flex-grow: 1;
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    font-family: var(--font-family-title);
}

#notificator_container_locale-mail
    > .locale-mail
    > .mail
    > .modify
    > .aumee--textarea {
    flex-grow: 1;
}

#notificator_container_locale-mail > .locale-mail > .vars {
    position: relative;

    border: 1px solid var(--selected-theme-tertiary-light);
    border-radius: 10px;

    padding: var(--padding-medium);

    max-width: 300px;
    width: 25%;
    min-width: 235px;
    height: calc(100% - var(--padding-medium) * 2);
}

@media screen and (max-width: 700px) {
    #notificator_container_locale-mail > .locale-mail {
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    #notificator_container_locale-mail > .locale-mail > .mail {
        width: calc(100% - 6px);
    }

    #notificator_container_locale-mail
        > .locale-mail
        > .mail
        > .render
        > .content {
        height: 10px;
    }

    #notificator_container_locale-mail > .locale-mail > .vars {
        max-width: 300px;
        width: 80%;
        min-width: 240px;

        height: calc(180px);
    }
}
