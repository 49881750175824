.log-this-out > .aumee--container[id*='container_inputs'] {
    width: calc(100% - var(--padding-medium) * 2);
}

.log-this-out > .aumee--container[id*='container_table'] {
    flex-grow: 1;
    width: calc(100% - var(--padding-epic) * 2);
    height: 25%;
}

.log-this-out .inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    height: 100%;
}

.log-this-out .inputs > div {
    margin: var(--margin-small);
}

/**
    SPECIFIC STYLE
**/

#log-this-out_select_type_options_error {
    color: var(--logs-error-color);
}

#log-this-out_select_type_options_status {
    color: var(--logs-status-color);
}

#log-this-out_select_type_options_analytics {
    color: var(--logs-analytics-color);
}

#log-this-out_select_type_options_info {
    color: var(--logs-info-color);
}

#log-this-out_select_type_options_debug {
    color: var(--logs-debug-color);
}

#log-this-out_table_logs > .body > .row > .cell[id*='_type'] {
    align-items: center;
}

#log-this-out_table_logs > .body > .row > .cell[id*='_data.status-code'] > span,
#log-this-out_table_logs > .body > .row > .cell[id*='_type'] > span {
    padding: var(--padding-small) var(--padding-small)
        calc(var(--padding-small) - 2px) var(--padding-small);
    text-transform: uppercase;
    color: var(--selected-theme-secondary-light);
    border-radius: 4px;
}

#log-this-out_table_logs
    > .body
    > .row
    > .cell[id*='_type']
    > span.value-error {
    background-color: var(--logs-error-color);
}

#log-this-out_table_logs
    > .body
    > .row
    > .cell[id*='_type']
    > span.value-status {
    background-color: var(--logs-status-color);
}

#log-this-out_table_logs
    > .body
    > .row
    > .cell[id*='_type']
    > span.value-analytics {
    background-color: var(--logs-analytics-color);
}

#log-this-out_table_logs > .body > .row > .cell[id*='_type'] > span.value-info {
    background-color: var(--logs-info-color);
}

#log-this-out_table_logs
    > .body
    > .row
    > .cell[id*='_type']
    > span.value-debug {
    background-color: var(--logs-debug-color);
}

#log-this-out_table_logs > .body > .row > .cell[id*='_data.status-code'] {
    align-items: center;
}

#log-this-out_table_logs
    > .body
    > .row
    > .cell[id*='_data.status-code']
    > span[class*='value-2'] {
    background-color: var(--status-2xx-color);
}

#log-this-out_table_logs
    > .body
    > .row
    > .cell[id*='_data.status-code']
    > span[class*='value-3'] {
    background-color: var(--status-3xx-color);
}

#log-this-out_table_logs
    > .body
    > .row
    > .cell[id*='_data.status-code']
    > span[class*='value-4'] {
    background-color: var(--status-4xx-color);
}

#log-this-out_table_logs
    > .body
    > .row
    > .cell[id*='_data.status-code']
    > span[class*='value-5'] {
    background-color: var(--status-5xx-color);
}
