.app-info-data {
    padding: var(--padding-medium);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.app-info-data > .inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-info-data > .icon {
    width: 100px;
    height: 100px;

    border: 2px dashed var(--selected-theme-primary-light);
    border-radius: 10px;
}

@media screen and (max-width: 700px) {
    .app-info-data {
        padding: 0px;
        flex-direction: column;
    }

    .app-info-data > .icon {
        width: 40px;
        height: 40px;
    }
}
