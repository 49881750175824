.follow-you > .aumee--container[id*='container_data'] {
    flex-grow: 1;
    width: calc(100% - var(--padding-epic) * 2);
    height: 25%;
}

.follow-you .inputs {
    flex-wrap: wrap;
    height: 100%;
}

.follow-you .inputs > div {
    margin: var(--margin-small);
}

.follow-you > .aumee--container[id*='container_data'] > .content {
    width: 100%;
    height: 100%;
}

.follow-you > .aumee--container[id*='container_data'] > .content > .render {
    overflow: scroll;
    justify-content: flex-start;
}

.follow-you > .aumee--container[id*='container_data'] > .content > .render,
.follow-you
    > .aumee--container[id*='container_data']
    > .content
    > .aumee--loader,
.follow-you
    > .aumee--container[id*='container_data']
    > .content
    > .aumee--data-list {
    flex-grow: 1;
    height: 100%;
}

.follow-you
    > .aumee--container[id*='container_data']
    > .content
    > .render
    > .trace-date {
    font-size: var(--font-size-button);
    font-weight: bold;
}

#follow-you_data-list_trace-ids {
    max-width: 250px;
}

#follow-you_data-list_trace-ids > .list > .display > .label > .text {
    font-size: var(--font-size-annotation);
}

#follow-you_data-list_trace-ids > .list > .display > .data.tag {
    padding: var(--padding-small) var(--padding-small)
        calc(var(--padding-small) - 2px) var(--padding-small);
    margin: 0px var(--margin-tiny);
    font-weight: unset;
    border-radius: 4px;
    color: white;
}

#follow-you_data-list_trace-ids > .list > .display > .data.\32xx {
    background-color: var(--status-2xx-color);
}

#follow-you_data-list_trace-ids > .list > .display > .data.\33xx {
    background-color: var(--status-3xx-color);
}

#follow-you_data-list_trace-ids > .list > .display > .data.\34xx {
    background-color: var(--status-4xx-color);
}

#follow-you_data-list_trace-ids > .list > .display > .data.\35xx {
    background-color: var(--status-5xx-color);
}

@media screen and (max-width: 700px) {
    .follow-you > .aumee--container[id*='container_data'] > .content {
        flex-direction: column;
    }

    #follow-you_data-list_trace-ids {
        max-width: 100%;
        width: 100%;
        height: 300px;
    }
}
