.menu {
    z-index: 11;
    background-color: var(--dark-theme-secondary-light);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu.top {
    width: 100%;
    min-height: 70px;
    flex-direction: row;
    box-shadow: 0px 4px 4px var(--universal-box-shadow-color);
}

.menu.left {
    width: fit-content;
    height: 100%;
    flex-direction: column;
    box-shadow: 4px 0px 4px var(--universal-box-shadow-color);
}

.menu > .mandatory-item {
    display: flex;
    justify-content: center;
}

.menu.top > .mandatory-item {
    width: 150px;
    min-width: 150px;
    padding: 0px var(--padding-medium);
    justify-content: end !important;
}

.menu.top > .mandatory-item.profile {
    width: fit-content !important;
    min-width: fit-content !important;
}

.menu.left > .mandatory-item {
    width: 150px;
    min-width: 150px;
    padding: var(--padding-large) var(--padding-medium);
}

.menu-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: opacity 0.2s ease-in-out;
}

.menu-items.visible {
    opacity: 1;
}

.menu-items.invisible {
    opacity: 0;
}

.menu.top > .menu-items {
    /* width: fit-content; */
    flex-direction: row;
    overflow-x: scroll;
    padding-bottom: var(--padding-tiny);
}

.menu.top > .menu-items > .tooltip {
    padding: 0px var(--padding-small);
}

.menu.top > .menu-items::-webkit-scrollbar {
    height: 4px;
}

.menu.left > .menu-items {
    flex-direction: column;
    overflow-y: scroll;
}

.menu.left > .menu-items > .tooltip {
    padding: var(--padding-small);
}

.menu.left > .menu-items::-webkit-scrollbar {
    width: 4px;
}
