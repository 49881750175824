.marketor > .aumee--container[id*='container_inputs'] {
    width: calc(100% - var(--padding-large) * 2);
}

.marketor > .aumee--container[id*='container_locale-mail'] {
    flex-grow: 1;
    width: calc(100% - var(--padding-epic) * 2);
    height: 25%;
}

#marketor_container_inputs > .inputs {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

#marketor_container_inputs > .inputs > .send-mass-mails {
    position: relative;
}

#marketor_container_inputs > .inputs > .send-mass-mails > .aumee--button {
    position: relative;
    z-index: 1;
}

#marketor_send-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 17px;
    width: 17px;
    z-index: 0;
}

#marketor_send-animation > .aumee--icon {
    position: absolute;
    top: 0px;
    left: 0px;

    --send-mail-duration: 1s;
}

#marketor_send-animation.running > .aumee--icon:nth-child(1) {
    animation: send-mail-1 var(--send-mail-duration) ease-out;
    animation-delay: calc((var(--send-mail-duration) / 6) * 0);
}

@keyframes send-mail-1 {
    /* You could think of as "step 1" */
    0% {
        top: 0px;
        left: 0px;
        opacity: 1;
    }
    /* You could think of as "step 2" */
    100% {
        top: -100px;
        left: 0px;
        opacity: 0;
    }
}

#marketor_send-animation.running > .aumee--icon:nth-child(2) {
    animation: send-mail-2 var(--send-mail-duration) ease-out;
    animation-delay: calc((var(--send-mail-duration) / 6) * 1);
}

@keyframes send-mail-2 {
    0% {
        top: 0px;
        left: 0px;
        opacity: 1;
    }
    100% {
        top: -67px;
        left: 74px;
        opacity: 0;
    }
}

#marketor_send-animation.running > .aumee--icon:nth-child(3) {
    animation: send-mail-3 var(--send-mail-duration) ease-out;
    animation-delay: calc((var(--send-mail-duration) / 6) * 2);
}

@keyframes send-mail-3 {
    0% {
        top: 0px;
        left: 0px;
        opacity: 1;
    }
    100% {
        top: 51px;
        left: 86px;
        opacity: 0;
    }
}

#marketor_send-animation.running > .aumee--icon:nth-child(4) {
    animation: send-mail-4 var(--send-mail-duration) ease-out;
    animation-delay: calc((var(--send-mail-duration) / 6) * 3);
}

@keyframes send-mail-4 {
    0% {
        top: 0px;
        left: 0px;
        opacity: 1;
    }
    100% {
        top: 95px;
        left: 31px;
        opacity: 0;
    }
}

#marketor_send-animation.running > .aumee--icon:nth-child(5) {
    animation: send-mail-5 var(--send-mail-duration) ease-out;
    animation-delay: calc((var(--send-mail-duration) / 6) * 4);
}

@keyframes send-mail-5 {
    0% {
        top: 0px;
        left: 0px;
        opacity: 1;
    }
    100% {
        top: 68px;
        left: -73px;
        opacity: 0;
    }
}

#marketor_send-animation.running > .aumee--icon:nth-child(6) {
    animation: send-mail-6 var(--send-mail-duration) ease-out;
    animation-delay: calc((var(--send-mail-duration) / 6) * 5);
}

@keyframes send-mail-6 {
    0% {
        top: 0px;
        left: 0px;
        opacity: 1;
    }
    100% {
        top: -76px;
        left: -64px;
        opacity: 0;
    }
}

#marketor_container_locale-mail > .locale-mail {
    height: 100%;
}

#marketor_container_locale-mail > .locale-mail > .not-found {
    font-size: var(--font-size-heading);
}

#marketor_container_locale-mail > .locale-mail > .mail {
    flex-grow: 1;
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#marketor_container_locale-mail > .locale-mail > .mail > .render {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#marketor_container_locale-mail > .locale-mail > .mail > .render > .title {
    text-align: center;
    font-size: var(--font-size-heading);
    width: 100%;
}

#marketor_container_locale-mail > .locale-mail > .mail > .render > .content {
    border: 1px solid var(--selected-theme-tertiary-light);

    flex-grow: 1;
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    font-family: var(--font-family-title);
}

#marketor_container_locale-mail > .locale-mail > .users {
    position: relative;

    border: 1px solid var(--selected-theme-tertiary-light);
    border-radius: 10px;

    padding: var(--padding-medium);
    text-align: center;

    max-width: 300px;
    width: 25%;
    min-width: 235px;
    height: calc(100% - var(--padding-medium) * 2);
}

#marketor_container_locale-mail > .locale-mail > .users > span {
    font-size: var(--font-size-heading);
    font-weight: bold;
}

#marketor_container_locale-mail > .locale-mail > .users > .total > span {
    text-align: center;
    width: 100px;
}

#marketor_container_locale-mail > .locale-mail > .users > .aumee--data-list {
    flex-grow: 1;
    width: calc(100% - var(--padding-medium) * 2);
}

@media screen and (max-width: 700px) {
    #marketor_container_locale-mail > .locale-mail {
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    #marketor_container_locale-mail > .locale-mail > .mail {
        width: calc(100% - 6px);
    }

    #marketor_container_locale-mail
        > .locale-mail
        > .mail
        > .render
        > .content {
        height: 10px;
    }

    #marketor_container_locale-mail > .locale-mail > .users {
        max-width: 300px;
        width: 80%;
        min-width: 240px;

        height: 300px;
    }
}
