.accesses > .aumee--container[id*='container_accounts'] {
    flex-grow: 1;
    width: calc(100% - var(--padding-epic) * 2);
    max-width: 800px;
    height: 25%;
}

.accesses > .aumee--container[id*='container_filters'] {
    width: 220px;
}

#accesses_container_filters {
    height: 120px;
}

#accesses_container_filters-add {
    height: 150px;
}

.accesses > .aumee--container[id*='container_filters'] > .account-selected,
.accesses > .aumee--container[id*='container_filters'] > .filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;

    animation: aumee--fadeIn 0.4s ease;
}

.accesses > .aumee--container[id*='container_filters'] > .filters > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.accesses
    > .aumee--container[id*='container_filters']
    > .filters
    > .type
    > .total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: var(--font-size-button);
    color: var(--selected-theme-tertiary-dark);
    opacity: 1;
    transition: opacity 0.2s ease;
}

.accesses
    > .aumee--container[id*='container_filters']
    > .filters
    > .type
    > .total.invisible {
    opacity: 0;
}

#accesses_container_accounts {
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#accesses_container_accounts > .table-switch {
    position: absolute;
    top: var(--padding-epic);
    left: var(--padding-epic);
    width: calc(100% - var(--padding-epic) * 2);
    height: calc(100% - var(--padding-epic) * 2);

    transition: transform 0.4s ease;
}

#accesses_container_accounts > .table-switch.selected {
    transform: translateX(0px) !important;
}

#accesses_container_accounts > .table-switch.not-selected {
    user-select: none;
    pointer-events: none;
}

.accesses
    > .aumee--container[id*='container_filters']
    > .account-selected
    > .account-name,
#accesses_table_accounts > .values > .row > .cell > .account-name,
.accesses
    > .aumee--container[id*='container_filters']
    > .account-selected
    > .app-access,
#accesses_table_accounts > .values > .row > .cell > .app-access,
#accesses_table_roles > .values > .row > .cell > .role-name,
#accesses_table_roles > .values > .row > .cell > .expiration-date,
#accesses_table_roles > .values > .row > .cell > .expiration-date .value,
#accesses_table_roles > .values > .row > .cell > .next-paiement-date {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accesses
    > .aumee--container[id*='container_filters']
    > .account-selected
    > .account-name
    > img,
#accesses_table_accounts > .values > .row > .cell > .account-name > img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: var(--margin-small);
}

.accesses
    > .aumee--container[id*='container_filters']
    > .account-selected
    > .account-name
    > span,
#accesses_table_accounts > .values > .row > .cell > .account-name > span,
#accesses_table_roles > .values > .row > .cell > .role-name > span {
    font-size: calc(var(--font-size-paragraph) * 2);
}

#accesses_table_roles > .values > .row > .cell > .role-name > .aumee--icon {
    margin-left: var(--margin-small);
}

#accesses_table_roles > .values > .row > .cell > .expiration-date {
    text-align: center;
    flex-direction: column;
}

#accesses_table_roles
    > .values
    > .row
    > .cell
    > .expiration-date
    .value
    > .modify-access {
    opacity: 1;
}

#accesses_table_roles
    > .values
    > .row
    > .cell
    > .expiration-date
    .value
    > .modify-access.invisible {
    opacity: 0;
}

#accesses_table_roles > .bottom-action > .add-access {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 700px) {
    .accesses
        > .aumee--container[id*='container_filters']
        > .account-selected
        > .account-name
        > span,
    #accesses_table_accounts > .values > .row > .cell > .account-name > span,
    #accesses_table_roles > .values > .row > .cell > .role-name > span {
        font-size: calc(var(--font-size-paragraph) * 1.5);
    }
}
