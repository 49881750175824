.supporter > .aumee--container[id*='container_inputs'] {
    width: calc(100% - var(--padding-medium) * 2);
}

.supporter > .aumee--container[id*='container_data'] {
    flex-grow: 1;
    width: calc(100% - var(--padding-epic) * 2);
    height: 25%;
}

.supporter .inputs > .aumee--flex-center,
.supporter .inputs {
    flex-wrap: wrap;
    height: 100%;
}

.supporter .inputs > .aumee--flex-center > div {
    margin: var(--margin-small);
}

.supporter .groups {
    margin-top: var(--margin-small);
}

/**
    Category colors for each buttons
**/

#supporter_button-group_category button[id*='_question'].selected > svg,
#supporter_button-group_category button[id*='_bug'].selected > svg,
#supporter_button-group_category button[id*='_other'].selected > svg {
    fill: var(--selected-theme-secondary-light) !important;
}

#supporter_button-group_category button[id*='_bug'].selected {
    background-color: var(--selected-theme-alert) !important;
}

#supporter_button-group_category button[id*='_bug'] > svg {
    fill: var(--selected-theme-alert) !important;
}

#supporter_button-group_category button[id*='_question'].selected {
    background-color: var(--selected-theme-warning) !important;
}

#supporter_button-group_category button[id*='_question'] > svg {
    fill: var(--selected-theme-warning) !important;
}

#supporter_button-group_category button[id*='_other'].selected {
    background-color: var(--selected-theme-box-shadow-color) !important;
}

#supporter_button-group_category button[id*='_other'] > svg {
    fill: var(--selected-theme-box-shadow-color) !important;
}

/**
    Status colors for each buttons
**/

#supporter_button-group_requests button.open.selected,
#supporter_button-group_status button[id*='_open'].selected {
    background-color: var(--status-5xx-color) !important;
}

#supporter_button-group_requests button.open > svg,
#supporter_button-group_status button[id*='_open'] > svg {
    fill: var(--status-5xx-color) !important;
}

#supporter_button-group_requests
    > .aumee--tooltip
    > button.in_progress.selected,
#supporter_button-group_status button[id*='_in_progress'].selected {
    background-color: var(--status-4xx-color) !important;
}

#supporter_button-group_requests button.in_progress > svg,
#supporter_button-group_status button[id*='_in_progress'] > svg {
    fill: var(--status-4xx-color) !important;
}

#supporter_button-group_requests button.pending.selected,
#supporter_button-group_status button[id*='_pending'].selected {
    background-color: var(--status-3xx-color) !important;
}

#supporter_button-group_requests button.pending > svg,
#supporter_button-group_status button[id*='_pending'] > svg {
    fill: var(--status-3xx-color) !important;
}

#supporter_button-group_requests button.closed.selected,
#supporter_button-group_status button[id*='_closed'].selected {
    background-color: var(--status-2xx-color) !important;
}

#supporter_button-group_requests button.closed > svg,
#supporter_button-group_status button[id*='_closed'] > svg {
    fill: var(--status-2xx-color) !important;
}

#supporter_button-group_status button.selected > svg,
#supporter_button-group_requests button.selected > svg {
    fill: var(--selected-theme-secondary-light) !important;
}

/**
*   Search container
*/

#supporter_button-group_requests {
    margin: auto;
}

#supporter_container_data > .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#supporter_container_data > .content > .row {
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
}

#supporter_container_data > .content > .row > .table {
    height: 100%;
    max-width: 100%;
    flex-grow: 1;
}

#supporter_container_data > .content > .row > .column > .totals {
    width: 100%;
}

#supporter_container_data > .content > .row > .column > .totals,
#supporter_container_data > .content > .row > .column,
#supporter_container_data > .content > .row {
    flex-wrap: wrap;
}

#supporter_container_data > .content > .row > .column:first-child {
    margin: 0px var(--margin-epic);
}

#supporter_container_data > .content > .row > .column > .totals,
#supporter_container_data > .content > .row > .column > .totals > div {
    flex-grow: 1;
}

#supporter_table_requests > .body > .row > .cell {
    align-items: center;
}

#supporter_table_requests > .body > .row > .cell > .id-display,
#supporter_table_requests > .body > .row > .cell > .date {
    text-align: center;
}

#supporter_container_data .cell > svg[id*='_validate-icon'],
#supporter_container_data .info > .value > svg[id*='_validate-icon'] {
    fill: var(--status-2xx-color) !important;
}

#supporter_container_data .cell > svg[id*='_pending-icon'],
#supporter_container_data .info > .value > svg[id*='_pending-icon'] {
    fill: var(--status-3xx-color) !important;
}

/**
*
* Request Container
*
**/

#supporter_container_data .request {
    flex-grow: 1;
    overflow: hidden;

    border: 2px solid var(--selected-theme-tertiary);
    border-radius: 10px;
}

#supporter_container_data .request > div.reduced {
    flex-grow: 0;
}

#supporter_container_data .request > div.reduced > button {
    margin: var(--margin-small);
}

#supporter_container_data .request > .infos {
    height: 100%;
    justify-content: space-evenly;
    border-right: 2px solid var(--selected-theme-tertiary);
    flex-grow: 1;
    transition: flex-grow 0.3s ease;
    overflow-y: scroll;
}

#supporter_container_data .request > .infos.reduced.mobile {
    overflow-y: visible;
    justify-content: center;
}

#supporter_container_data .request > .messages.reduced {
    justify-content: center;
}

#supporter_container_data .request > .infos > .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    text-align: center;

    width: calc(100% - var(--padding-large) * 2);
    padding: var(--padding-medium) var(--padding-large);
    border-bottom: 2px solid var(--selected-theme-tertiary);
}

#supporter_container_data .request > .infos > .info.flag {
    height: 40px;
    flex-grow: 0;
}

#supporter_container_data .request > .infos > .info:last-child {
    border-bottom: none;
}

#supporter_container_data .request > .infos > .info > .title {
    font-size: var(--font-size-annotation);
    margin-bottom: var(--margin-small);
}

#supporter_container_data .request > .infos > .info > .value {
    display: flex;
    align-items: center;
    justify-content: center;
}

#supporter_container_data .request > .infos > .info > .value > svg {
    margin-right: var(--margin-small);
}

#supporter_container_data .request > .messages {
    height: 100%;
    justify-content: flex-start;
    flex-grow: 1;
    transition: flex-grow 0.3s ease;
}

#supporter_container_data .request > .messages > .short-description {
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(100%);
    font-size: var(--font-size-heading);
    border-bottom: 2px solid var(--selected-theme-tertiary);
}

#supporter_editable-text_short_description {
    padding: var(--padding-medium) var(--padding-large);
    min-height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
}

#supporter_container_data .request > .messages > .chat {
    width: calc(100% - var(--padding-medium) * 2);
    flex-grow: 1;
    padding: var(--padding-medium);

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: var(--margin-small);

    overflow-y: scroll;
    overflow-x: hidden;
}

#supporter_container_data .request > .messages > .chat > .message {
    display: flex;
    flex-direction: column;
    padding: var(--padding-medium);
    max-width: calc(60% - var(--padding-medium));
    box-shadow: 0px 0px 2px var(--selected-theme-box-shadow-color);

    border-radius: 10px;
}

#supporter_container_data .request > .messages > .chat > .message.left {
    background-color: var(--selected-theme-secondary);
    color: var(--selected-theme-tertiary);
    align-self: flex-start;
}

#supporter_container_data .request > .messages > .chat > .message.right {
    background-color: var(--selected-theme-primary);
    color: var(--selected-theme-secondary-light);
    align-self: flex-end;
}

#supporter_container_data .request > .messages > .chat > .message.note {
    background-color: var(--selected-theme-warning);
    color: var(--selected-theme-tertiary);
    align-self: flex-end;
}

#supporter_container_data .request > .messages > .chat > .message > .value {
    align-self: flex-start;
    font-size: var(--font-size-paragraph);
    margin-bottom: var(--margin-small);
}

#supporter_container_data .request > .messages > .chat > .message > .date {
    align-self: flex-end;
    font-size: var(--font-size-annotation);
}

#supporter_container_data .request > .messages > .add-message {
    width: calc(100% - var(--padding-medium) * 2);
    height: 100px;
    min-height: 100px;
    padding: var(--padding-medium);
}

#supporter_container_data
    .request:has(> .infos.reduced.mobile)
    > .messages
    > .add-message {
    flex-wrap: wrap;
    overflow-y: scroll;
}

#supporter_container_data .request > .messages > .add-message > .actions {
    width: 200px;
}

#supporter_container_data
    .request:has(> .infos.reduced.mobile)
    > .messages
    > .add-message
    > .actions {
    margin-top: var(--margin-small);
}

#supporter_container_data
    .request
    > .messages
    > .add-message
    > .aumee-text-area {
    flex-grow: 1;
}

#supporter_select_request-category_options_bug {
    color: var(--selected-theme-alert-dark);
}

#supporter_select_request-category_options_feature {
    color: var(--selected-theme-primary);
}

#supporter_select_request-category_options_question {
    color: var(--selected-theme-warning-dark);
}

#supporter_select_request-category_options_other {
    color: var(--selected-theme-box-shadow-color);
}

@media screen and (max-width: 1450px) {
    #supporter_container_data > .content > .row {
        height: calc(100% - 45px);
    }

    #supporter_container_data > .content > .row > .column > .totals {
        flex-direction: row;
    }

    #supporter_container_data > .content > .row > .table {
        height: calc(100% - 92px);
    }

    #supporter_container_data > .content > .row > .column:first-child {
        flex-grow: 1;
        margin: var(--margin-none) !important;
    }
}

@media screen and (max-width: 700px) {
    #supporter_container_data > .content > .row > .table {
        min-height: 200px;
    }

    #supporter_container_data > .content > .row > .table {
        height: calc(100% - 60px);
    }
}
