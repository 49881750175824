.heat-map > .aumee--container[id*='container_data'] {
    flex-grow: 1;
    width: calc(100% - var(--padding-epic) * 2);
    height: 25%;
}

.heat-map .inputs {
    flex-wrap: wrap;
    height: 100%;
}

.heat-map .inputs > div {
    margin: var(--margin-small);
}

.heat-map > .aumee--container[id*='container_data'] > .content {
    width: 100%;
    height: 100%;
}

.heat-map > .aumee--container[id*='container_data'] > .content > .render {
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: flex-start;
}

.heat-map > .aumee--container[id*='container_data'] > .content > .render,
.heat-map
    > .aumee--container[id*='container_data']
    > .content
    > .aumee--data-list {
    flex-grow: 1;
    height: 100%;
}

.heat-map
    > .aumee--container[id*='container_data']
    > .content
    > .render
    > .session {
    width: 100%;
    justify-content: flex-start;
}

.heat-map
    > .aumee--container[id*='container_data']
    > .content
    > .render
    > .session
    > .log-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
    width: 100%;
}

.heat-map
    > .aumee--container[id*='container_data']
    > .content
    > .render
    > .session
    > .log-data
    > span {
    flex-grow: 1;
    padding: var(--padding-small);
}

.heat-map
    > .aumee--container[id*='container_data']
    > .content
    > .render
    > .container-iframe {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    position: relative;
}

.heat-map
    > .aumee--container[id*='container_data']
    > .content
    > .render
    > .container-iframe
    > .wrap-iframe {
    position: relative;
    max-height: 600px;
    border: 2px solid var(--selected-theme-tertiary);
}

.heat-map
    > .aumee--container[id*='container_data']
    > .content
    > .render
    > .container-iframe
    > .wrap-iframe
    > iframe {
    zoom: 0.39;
    user-select: none;
    pointer-events: none;
    border: none;
}

.heat-map
    > .aumee--container[id*='container_data']
    > .content
    > .render
    > .container-iframe
    > .wrap-iframe
    > .pointer {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--logs-info-color);
    border: 2px solid var(--selected-theme-tertiary);
    transform: translate(-50%, -50%);

    transition: top 0.3s ease, left 0.3s ease;
}

#heat-map_data-list_session-ids {
    max-width: 250px;
    min-width: 250px;
}

#heat-map_data-list_session-ids > .list > .display > .label > .text {
    font-size: var(--font-size-annotation);
}

@media screen and (max-width: 700px) {
    .heat-map > .aumee--container[id*='container_data'] > .content {
        flex-direction: column;
    }

    #heat-map_data-list_session-ids {
        max-width: 100%;
        width: 100%;
        height: 300px;
    }
}
