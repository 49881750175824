.editable-text {
    position: relative;

    transition: background-color 0.3s ease;
}

.editable-text.edit {
    cursor: pointer;
}

.editable-text.edit.display:hover {
    background-color: var(--selected-theme-secondary);
}

.editable-text > div {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editable-text > .loader {
    position: absolute;
    background-color: var(--selected-theme-secondary-light);
    z-index: 1;
}

.editable-text > div > .aumee--text-area {
    flex-grow: 1;
}

.editable-text > div > .aumee--text-area:nth-child(2) {
    margin-top: var(--margin-medium);
}

.editable-text > div > .icon {
    position: absolute;
    display: flex;
    align-items: center;

    opacity: 0;
    transition: opacity 0.2s ease;
}

.editable-text > div > .icon.top-left {
    top: 0px;
    left: 0px;
}

.editable-text > div > .icon.top-right {
    top: 0px;
    right: 0px;
}

.editable-text.edit > div:has(.aumee--text-area > textarea:focus) > .icon,
.editable-text.edit > div > .icon:has(.aumee--button:focus),
.editable-text.edit:hover > div > .icon {
    opacity: 1;
}
